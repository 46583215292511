import "./Dictaphone.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import AppContext from 'contexts/AppContext';
import StartIcon from 'images/record.svg';
import StopIcon from 'images/stop.svg';
import gsap from 'gsap';

import cn from 'classnames';

function Dictaphone() {

  const [commandToSend, setCommandToSend] = useState();
  //COMMANDS
  const commands = [

    {
      command: ['Azul'],
      callback: ({ command }) => gsap.delayedCall(2, evalCorrectMessage, [command])
    },
    {
      command: ['Rojo', 'Colorado', 'Celeste', 'Verde', 'Violeta', 'Naranja', 'Blanco', 'Negro', 'Amarillo', 'Turquesa'],
      callback: ({ command }) => gsap.delayedCall(2, evalWrongMessage, [command])
    }

  ]
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition({ commands });
  const {  micStatus, setMicStatus, setCurrentTranscription, messages, setMessages, setBotMessage } = useContext(AppContext);

  useEffect(() => {

    if(listening){
      setMicStatus("on");
      setCurrentTranscription(transcript);
    }else {
      console.log("off recording", transcript);
      setMicStatus("off");
      //console.log(messages.push(transcript), messages);
      // var newMessages = messages.push(transcript)
      if(transcript.length > 0){
        var messageElement = {type: 'user', message:transcript }
        var joined = messages.concat(messageElement);
        setMessages(joined);

      }

    }
  }, [listening]);
  useEffect(() => {

    if(listening){
      setCurrentTranscription(transcript);
    }else {

    }
  }, [transcript]);

  //CHANGE FILTER CALLBACK
  function handleStartListener() {
      SpeechRecognition.startListening({ language: 'es-AR' })
  }



  //COMMANDS ACTIONS
  function evalCorrectMessage(command) {
    var messageElement = {type: 'bot', message:'¡Si!, Amo el azul 💙' }
    setBotMessage(messageElement);
  }
  function evalWrongMessage(command) {
    var messageElement = {type: 'bot', message:'Nop, ese no es mi color favorito.' }
    setBotMessage(messageElement);
  }
  function evalOtherMessage(command) {
    var messageElement = {type: 'bot', message:'¿Eso es un color?, prueba con los colores típicos.' }
    setBotMessage(messageElement);
  }




    return (
      <div className={  cn({
          ["Dictaphone"]: true,
        })} data-scroll data-scroll-position="bottom" data-scroll-sticky data-scroll-target="#scrollTarget">
        <div className="controls">
          <div className="controls__transcript">
            {micStatus && micStatus === "on" && (
              <span>{transcript}</span>
            )}

          </div>
          <div className="controls__buttons">
            {micStatus && micStatus === "on" && (
              <button className="button" onClick={SpeechRecognition.stopListening}>
                <img src={StopIcon} alt="Stop Recording"/>
              </button>
            )}
            {micStatus && micStatus === "off" && (
              <button className="button" onClick={handleStartListener}>
                <img src={StartIcon} alt="Start Recording"/>
              </button>
            )}


          </div>



        </div>

      </div>
    );

}
export default Dictaphone;
