import React, { useEffect, useState, useContext, useRef } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import { AnimatePresence } from "framer-motion";
import Home from 'pages/Home/Home';
import About from 'pages/About/About';
import Nav from 'components/Nav/Nav';
import AppContext from 'contexts/AppContext';
import cn from 'classnames';


function App() {
  const location = useLocation();
  const containerRef = useRef(null);
  //contexts
  const [currentSection, setCurrentSection] = useState("home");
  //Mic Status
  const [micStatus, setMicStatus] = useState("off");
  //Transcription
  const [currentTranscription, setCurrentTranscription] = useState("");
  const [messages, setMessages] = useState([{type: 'bot', message:'Hola, adivina mi color favorito. 😊' }]);
  const [botMessage, setBotMessage] = useState();
  //
  return (


          <AppContext.Provider value={{
              setCurrentSection: setCurrentSection,
              currentSection : currentSection,
              micStatus: micStatus,
              setMicStatus: setMicStatus,
              currentTranscription: currentTranscription,
              setCurrentTranscription: setCurrentTranscription,
              messages: messages,
              setMessages: setMessages,
              botMessage: botMessage,
              setBotMessage: setBotMessage,
           }}>
          <LocomotiveScrollProvider
            options={{
                smooth: true,
                reloadOnContextChange: false,
            }}
            watch={[] }
            containerRef={containerRef}
          >
            <div  className =  {  cn({
                ["App"]: true,
                ["Recording"]: micStatus === "on",
              })}>

              <main data-scroll-container ref={containerRef}>
                <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname} >

                    <Route path="/" exact>
                      <Home />
                    </Route>

                    <Route path="/about" exact>
                      <About />
                    </Route>

                  </Switch>
                </AnimatePresence>
              </main>
            </div>
          </LocomotiveScrollProvider>
        </AppContext.Provider>

  );
}

export default App;
