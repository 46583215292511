import React, { useEffect, useState, useContext, useRef } from "react";
import { motion } from "framer-motion"
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import AppContext from 'contexts/AppContext';
import cn from 'classnames';

function About() {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  //scroll
  const { scroll } = useLocomotiveScroll();
  useEffect(() => {
    setCurrentSection("about");
    if(scroll){
      scroll.destroy();
      scroll.init();
    }
  }, [scroll]);
  //Handle advanced page transitions
  const pageVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: { opacity: 0 , transition:{ duration: .5}}
  }
  const pageAnimationStart = e => {
  };
  const pageAnimationComplete = e => {
  };


  return (
  <motion.div
    exit="exit"
    animate="visible"
    initial="hidden"
    variants={pageVariants}
    onAnimationStart={pageAnimationStart}
    onAnimationComplete={pageAnimationComplete}
  >

    <div className={  cn({
            ["page"]: true,
            ["about"]: true
          })}
    >

      <section>
        <h1>{currentSection}</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore hic nobis magnam incidunt quos, odit beatae delectus cupiditate nostrum architecto provident ea, odio nesciunt voluptatem harum voluptatum obcaecati excepturi sint!</p>
      </section>
    </div>
  </motion.div>
  )
}

export default About;
