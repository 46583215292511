import { Component } from "react";
import "./Header.scss";

import Brand from "images/bebot-brand.svg";

export default class Header extends Component {
  render() {


    let className = "Header" ;

    return (
      <div className={className} >
        <div className="logo" >
          <img src={Brand} alt="BeBot"/>
        </div>
        <div className="title" >
          BeBot Web Speech API Test
        </div>

      </div>
    );
  }
}
