import "./Message.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import AppContext from 'contexts/AppContext';
import Element from 'components/Element/Element'

import cn from 'classnames';

function Message({messageContent, type}) {
  const {  micStatus, setMicStatus, setCurrentTranscription, messages, setMessages } = useContext(AppContext);

  return (
    <Element>
      <div className={  cn({
          ["Message"]: true,
          ["Message--User"]: type === "user",
          ["Message--Bot"]: type === "bot",
        })} >

          {messageContent}

      </div>
      </Element>
    );

}
export default Message;
