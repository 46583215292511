import React, { useEffect, useState, useContext, useRef } from "react";
import { motion } from "framer-motion"
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import AppContext from 'contexts/AppContext';
import Dictaphone from 'components/Dictaphone/Dictaphone'
import Header from 'components/Header/Header'
import Message from 'components/Message/Message'
import cn from 'classnames';
import gsap from 'gsap';

function Home() {
  const {  currentSection, setCurrentSection, currentTranscription, setCurrentTranscription, messages, setMessages, micStatus, botMessage } = useContext(AppContext);
  //scroll
  const { scroll } = useLocomotiveScroll();
  useEffect(() => {

    setCurrentSection("home");

    if(scroll){
      scroll.destroy();
      scroll.init();
    }
  }, [scroll]);

  useEffect(() => {
    if(scroll){
      //scroll.scrollTo(document.getElementById("messages").lastElementChild);
      gsap.delayedCall(.5, moveScroll);
    }
  }, [messages]);

  useEffect(() => {
    if(botMessage){
      var joined = messages.concat(botMessage);
      setMessages(joined);
      console.log("el bot message", botMessage);
    }
  }, [botMessage]);

  function moveScroll() {
    scroll.scrollTo("bottom");
  }
  //Handle advanced page transitions
  const pageVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: { opacity: 0 , transition:{ duration: .5}}
  }
  const pageAnimationStart = e => {
  };
  const pageAnimationComplete = e => {
  };

  return (
  <motion.div
    exit="exit"
    animate="visible"
    initial="hidden"
    variants={pageVariants}
    onAnimationStart={pageAnimationStart}
    onAnimationComplete={pageAnimationComplete}
  >
    <div className={  cn({
            ["page"]: true,
            ["home"]: true
          })}
    >

      <div data-sroll-section id="scrollTarget">
        <section>
          <Header />
          <div className="transcript" id="messages">
            {messages && messages.map((item, index) => (
              <Message  key={index} messageContent={item.message} type={item.type} />
            )) }

          </div>
          <Dictaphone></Dictaphone>
        </section>
      </div>
    </div>
  </motion.div>
  )
}

export default Home;
//
// {messages && messages.map((item, index) => (
//   <h1  key={index}>item</h1>
// )) }
